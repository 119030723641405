import { DEFAULT_MODEL } from "./openai";

export const models = ["gpt-4", "gpt-3.5"] as const;

const modelSettings: Record<
  Model,
  Record<"fullName" | "displayName", string>
> = {
  "gpt-3.5": {
    fullName: "gpt-3.5-turbo-1106",
    displayName: "Turbo Model",
  },
  "gpt-4": { fullName: "gpt-4", displayName: "Default Model" },
};

export type Model = (typeof models)[number];

export const toValidModel = (model: string): Model => {
  if (models.includes(model as Model)) return model as Model;
  return DEFAULT_MODEL;
};

export const toFullValidModel = (model: string): string => {
  if (model in modelSettings) return modelSettings[model as Model].fullName;
  return modelSettings[DEFAULT_MODEL].fullName;
};

export const toDisplayName = (model: Model): string => {
  if (model in modelSettings) return modelSettings[model as Model].displayName;
  return modelSettings[DEFAULT_MODEL].displayName;
};
