import Prism from "prismjs";
import "prismjs/components/prism-javascript";
import "prismjs/components/prism-typescript";

import { ChevronUp, DownloadCloud, FolderOpen, Save } from "lucide-react";

Prism.languages.commentsOnly = {
  comment: Prism.languages.javascript["comment"],
};

Prism.languages.synapse = {
  highlight: /\$[A-Z]+\$/,
  default: /(.|\n)*/gm,
};

import Editor from "react-simple-code-editor";

const LoadingIndicator = ({ load }: { load: boolean }) => {
  return (
    <>
      <Text>{load ? "applying changes" : "ready"}</Text>
      <Box
        w={2}
        h={2}
        rounded="full"
        bg={load ? "brandFull" : "green.300"}
        className={uif("pulse-bg", load)}
      />
    </>
  );
};

const LOADING_INDICATOR_TIMEOUT = 1000;

const defaultEditorProps = {
  fontFamily: "var(--chakra-fonts-mono)",
  fontSize: 10,
  color: "var(--chakra-colors-whiteAlpha-500)",
  minHeight: "100%",
};

import {
  Box,
  Button,
  HStack,
  Heading,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react";
import useSWR from "swr";
import { fetcher } from "@/lib/api";
import usePromptStore from "@/stores/promptStore";
import { PromptInfo } from "@/data/types";
import { useSupabaseClient } from "@supabase/auth-helpers-react";
import { HamburgerIcon } from "@chakra-ui/icons";
import { useTimeoutFn } from "react-use";
import { uif } from "@/lib/helpers";
import logError from "@/lib/logging";

interface ProModeDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  promptKey: string;
  onSubmit: (usePro: boolean) => void;
  onToggle: () => void;
}

const ProModeDrawer: FunctionComponent<ProModeDrawerProps> = ({
  isOpen,
  promptKey,
  title,
  onSubmit,
  onToggle,
}) => {
  const { updatePrompt, getPrompt, updateUserPromptParam, resetUserToSystem } =
    usePromptStore();
  const prompt = getPrompt(promptKey);

  const { data, isLoading, error } = useSWR(
    `/api/prompts/${promptKey}`,
    fetcher,
    {
      revalidateOnFocus: false,
    },
  );

  const [queryLoading, setQueryLoading] = useState<
    Record<keyof PromptInfo, boolean>
  >({
    introTemplate: false,
    typeAnnotations: false,
  });

  const queriesLoading = !Object.values(queryLoading).every(elem => !elem);

  const resetIntro = useTimeoutFn(
    () => setQueryLoading(s => ({ ...s, introTemplate: false })),
    LOADING_INDICATOR_TIMEOUT,
  )[2];

  const resetTypes = useTimeoutFn(
    () => setQueryLoading(s => ({ ...s, typeAnnotations: false })),
    LOADING_INDICATOR_TIMEOUT,
  )[2];

  const setLoading = (k: keyof PromptInfo) => {
    /* update the loadingstate */
    setQueryLoading(state => ({ ...state, [k]: true }));
    if (k === "typeAnnotations") {
      resetTypes();
    } else {
      resetIntro();
    }
  };

  useEffect(() => {
    if (!data) return;
    /* TODO: check if user input is overwritten */
    /* if custom (default) prompt -> copy this into edit */
    updatePrompt(promptKey, {
      title: promptKey,
      system: { ...data.data },
      user: null,
      edit: { ...data.data },
    });
  }, [data, updatePrompt, promptKey]);

  const onPromptChange = (key: keyof PromptInfo, v: string) => {
    updateUserPromptParam(promptKey, key, v);
    setLoading(key);
  };

  const supabaseClient = useSupabaseClient();

  const onResetPrompt = useCallback(() => {
    resetUserToSystem(promptKey);
  }, [promptKey, resetUserToSystem]);

  const onSavePrompt = useCallback(async () => {
    /* we take the current edited prompt and save it to the db */
    if (!prompt) return;
    const newPrompt = {
      title: prompt.title,
      intro_template: prompt.edit.introTemplate,
      type_annotations: prompt.edit.typeAnnotations,
    };
    const { error } = await supabaseClient.from("prompts").insert(newPrompt);
    // eslint-disable-next-line no-console
    if (error) console.error(error);
  }, [prompt, supabaseClient]);

  const onLoadPrompt = useCallback(async () => {
    /* we get the latest prompt from the user db. if it doesnt exist
    we get the default one and update the state */
    const { data: userDefault, error } = await supabaseClient
      .from("prompts")
      .select("intro_template, type_annotations")
      .eq("title", promptKey)
      .order("created_at", { ascending: false })
      .limit(1);

    let dataToWrite: PromptInfo | null = null;

    if (error || userDefault.length !== 1) {
      dataToWrite = { ...data.system };
    } else {
      dataToWrite = {
        typeAnnotations: userDefault[0].type_annotations,
        introTemplate: userDefault[0].intro_template,
      };
    }

    if (!dataToWrite) {
      /* If it is still null we have some kind of problem */
      // eslint-disable-next-line no-console
      logError(error ?? "Failed loading prompts");
      return;
    }

    updateUserPromptParam(
      promptKey,
      "introTemplate",
      dataToWrite.introTemplate,
    );
    updateUserPromptParam(
      promptKey,
      "typeAnnotations",
      dataToWrite.typeAnnotations,
    );
  }, [data, promptKey, supabaseClient, updateUserPromptParam]);

  return (
    <VStack
      gridArea="drawer"
      position="relative"
      w={96}
      p={4}
      align="left"
      spacing={6}
      bg="black"
      borderLeftWidth={1}
      borderLeftColor="brandFull"
      boxShadow={isOpen ? "highlight" : "none"}
      color="white"
      fontFamily="mono">
      <HStack justifyContent="space-between">
        <Heading size="md" fontFamily="mono" textTransform="lowercase">
          {title.replaceAll(" ", "-")}
        </Heading>
        <Menu variant="code">
          <MenuButton
            as={IconButton}
            aria-label="Options"
            icon={<HamburgerIcon />}
            variant="ghost"
            color="white"
            p={2}
            rounded="none"
            _active={{ bg: "whiteAlpha.200", outline: "1px solid white" }}
          />
          <MenuList>
            <MenuItem
              icon={
                <Icon
                  boxSize={3}
                  as={FolderOpen}
                  display="flex"
                  alignItems="center"
                />
              }
              aria-label="load"
              onClick={onLoadPrompt}>
              load custom
            </MenuItem>
            <MenuItem
              icon={
                <Icon
                  boxSize={3}
                  as={Save}
                  display="flex"
                  alignItems="center"
                />
              }
              aria-label="save"
              onClick={onSavePrompt}>
              save custom
            </MenuItem>
            <MenuDivider />
            <MenuItem
              icon={
                <Icon
                  boxSize={3}
                  as={DownloadCloud}
                  display="flex"
                  alignItems="center"
                />
              }
              aria-label="load-system"
              onClick={onResetPrompt}>
              load system
            </MenuItem>
          </MenuList>
        </Menu>
      </HStack>
      {isLoading && <Spinner />}
      {error && <Text>there was an error</Text>}
      {prompt?.edit && (
        <>
          <VStack align="left" flex="1" w="full" overflow="hidden">
            <HStack fontSize="12px">
              <Heading size="xs" fontFamily="mono" flex="1">
                intro
              </Heading>

              <LoadingIndicator load={queryLoading.introTemplate} />
            </HStack>
            <Box flex="1" overflowY="auto">
              <Editor
                value={prompt.edit.introTemplate}
                onValueChange={value => onPromptChange("introTemplate", value)}
                highlight={code =>
                  Prism.highlight(code, Prism.languages.synapse, "synapse")
                }
                id="edit"
                padding={10}
                style={{
                  ...defaultEditorProps,
                }}
              />
            </Box>
          </VStack>

          <VStack align="left" flex="1" overflow="hidden">
            <HStack fontSize="12px">
              <Heading size="xs" fontFamily="mono" flex="1">
                types
              </Heading>
              <LoadingIndicator load={queryLoading.typeAnnotations} />
            </HStack>
            <Box overflowY="auto" flex="1">
              <Editor
                value={prompt.edit.typeAnnotations}
                onValueChange={value =>
                  onPromptChange("typeAnnotations", value)
                }
                highlight={code =>
                  Prism.highlight(
                    code,
                    Prism.languages.commentsOnly,
                    "typescript",
                  )
                }
                padding={10}
                id="edit"
                style={{
                  ...defaultEditorProps,
                }}
              />
            </Box>
          </VStack>
          <Button
            variant="code"
            isLoading={queriesLoading}
            loadingText="applying prompts"
            onClick={() => onSubmit(true)}>
            Generate with custom prompt
          </Button>
        </>
      )}

      <Button
        bg="brandFull"
        position="absolute"
        top="50%"
        /* left="-100%" */
        paddingX={8}
        left="0"
        height="40px"
        iconSpacing={2}
        fontSize="md"
        roundedBottom={0}
        roundedTop={20}
        transform="translate(-50%, -100%) rotate(-90deg)"
        transformOrigin="bottom"
        onClick={onToggle}
        rightIcon={
          <Icon
            boxSize={6}
            as={ChevronUp}
            transition="all 500ms"
            transform={uif("rotate(180deg)", isOpen)}
          />
        }>
        PRO MODE
      </Button>
    </VStack>
  );
};

export default ProModeDrawer;
