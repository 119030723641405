import { create } from "zustand";
import { Result as DeviceResult } from "@/data/device-messages";
import { createEmptyDeviceState } from "../res/placeholderData";
import {
  EditableDataExportObject,
  EditableDataObject,
  toEditable,
} from "@/lib/editableData";
import {
  CrmBlockOutput,
  HeadunitOutput,
} from "@/data/device-messages/types.SCHEMA";
import { Channel } from "@/types/device";
import { Evaluation } from "@/components/FeedbackMessage";

export type DeviceType = keyof DeviceMessagesState;

const invalidatedDevices: Partial<GeneratorState> = {
  deviceMessages: createEmptyDeviceState(),
};

const invalidatedBrief: Partial<GeneratorState> = {
  brief: new EditableDataObject(""),
  ...invalidatedDevices,
};

export interface GeneratorStateFields {
  productDescription: string;
  tonality: number;
  useBulletPoints: boolean;
  additionalInfo: string;
  brief: EditableDataObject<string>;
  sources: string[];
  selectedChannels: Channel[];
  feedback: Record<string, Evaluation>;
  deviceMessages: {
    app: EditableDataObject<DeviceResult>;
    mail: EditableDataObject<DeviceResult>;
    headunit: EditableDataObject<HeadunitOutput>;
    crmBlock: EditableDataObject<CrmBlockOutput>;
  };
}

export interface GeneratorStateFieldsExport {
  productDescription: string;
  tonality: number;
  useBulletPoints: boolean;
  additionalInfo: string;
  brief: EditableDataExportObject<string>;
  selectedChannels: Channel[];
  sources: string[];
  feedback: Record<string, Evaluation>;
  deviceMessages: {
    app: EditableDataExportObject<DeviceResult>;
    mail: EditableDataExportObject<DeviceResult>;
    headunit: EditableDataExportObject<HeadunitOutput>;
    crmBlock: EditableDataExportObject<CrmBlockOutput>;
  };
}

export type DeviceMessagesState = GeneratorStateFields["deviceMessages"];

export interface GeneratorStateActions {
  setProductDescription: (m: string) => void;
  setTonality: (t: number) => void;
  setUseBulletPoints: (v: boolean) => void;
  setAdditionalInfo: (v: string) => void;

  setSelectedChannels: (c: Channel[]) => void;
  addToSelectedChannels: (c: Channel) => void;

  setBrief: (m: EditableDataObject<string>) => void;
  setSources: (m: string[]) => void;
  setFeedback: (name: string, feedback: Evaluation) => void;
  deleteFeedback: () => void;
  setDeviceMessages: (m: DeviceMessagesState) => void;
  updateDeviceMessages: (m: Partial<DeviceMessagesState>) => void;

  setMessageForDevice: (
    data: EditableDataObject<DeviceResult>,
    device: DeviceType,
  ) => void;
  importState: (state: GeneratorStateFieldsExport) => void;
}

export type GeneratorState = GeneratorStateFields & GeneratorStateActions;

const useGeneratorStore = create<GeneratorState>((set, get) => ({
  productDescription: "",
  setProductDescription: m =>
    set({
      productDescription: m,
      ...invalidatedBrief,
    }),
  tonality: 5,
  setTonality: n =>
    set({
      tonality: n,
      ...invalidatedDevices,
    }),
  useBulletPoints: false,

  selectedChannels: ["mail", "app"] as Channel[],
  setSelectedChannels: c => set({ selectedChannels: c }),
  addToSelectedChannels: c =>
    set({ selectedChannels: [...get().selectedChannels, c] }),
  setUseBulletPoints: (v: boolean) =>
    set({
      useBulletPoints: v,
      ...invalidatedBrief,
    }),
  additionalInfo: "",
  setAdditionalInfo: (v: string) =>
    set({ additionalInfo: v, ...invalidatedBrief }),

  brief: new EditableDataObject(""),
  setBrief: m => set({ brief: m, deviceMessages: createEmptyDeviceState() }),

  sources: [],
  setSources: m => set({ sources: m }),

  feedback: {},
  setFeedback: (name, feedback) => {
    set({ feedback: { ...get().feedback, [name]: feedback } });
  },
  deleteFeedback: () => set({ feedback: {} }),

  deviceMessages: createEmptyDeviceState(),
  setDeviceMessages: d => set({ deviceMessages: d }),
  updateDeviceMessages: d => {
    return set({ deviceMessages: { ...get().deviceMessages, ...d } });
  },

  setMessageForDevice: (data, device) => {
    const newDeviceMessages = { ...get().deviceMessages, [device]: data };
    return set({ deviceMessages: newDeviceMessages });
  },

  importState: state => {
    const newState: GeneratorStateFields = {
      ...state,
      selectedChannels: state.selectedChannels ?? ["app", "mail"],

      brief: new EditableDataObject("").import(state.brief ?? toEditable("")),
      deviceMessages: {
        app: new EditableDataObject<DeviceResult>({
          title: "",
          message: "",
        }).import(
          state.deviceMessages?.app ?? toEditable({ message: "", title: "" }),
        ),
        mail: new EditableDataObject<DeviceResult>({
          title: "",
          message: "",
        }).import(
          state.deviceMessages?.mail ?? toEditable({ message: "", title: "" }),
        ),
        headunit: new EditableDataObject<HeadunitOutput>({
          title: "",
          message: "",
        }).import(
          state.deviceMessages?.headunit ??
            toEditable({ title: "", message: "" }),
        ),
        crmBlock: new EditableDataObject<CrmBlockOutput>({
          title: "",
          message: "",
        }).import(
          state.deviceMessages?.crmBlock ??
            toEditable({ title: "", message: "" }),
        ),
      },
    };
    return set({ ...newState });
  },
}));

export const useGeneratorStateFields: () => GeneratorStateFields = () =>
  useGeneratorStore(state => ({
    productDescription: state.productDescription,
    tonality: state.tonality,
    useBulletPoints: state.useBulletPoints,
    additionalInfo: state.additionalInfo,
    brief: state.brief,
    sources: state.sources,
    feedback: state.feedback,
    deviceMessages: state.deviceMessages,
    selectedChannels: state.selectedChannels,
  }));

export default useGeneratorStore;
